import Vue from 'vue'
import VeeValidate from 'vee-validate'
import { Auth } from 'aws-amplify'

Vue.use(VeeValidate)

export default {
  data () {
    return {
      user: {
        Username: '',
        Password: ''
      },
      loading: false,
      showPassword: false,
      newPasswordRequired: false,
      logo: process.env.S3 + window.location.host + '/loginLogo.png',
      background: process.env.S3 + window.location.host + '/loginBackground.gif',
      homeCareLogo: process.env.S3 + window.location.host + '/poweredByHomeCareLogo.png',
      newPassword: '',
      newPasswordUser: null,
      forgotPassword: false,
      code: ''

    }
  },
  mounted () {
    if (this.$route.query.username && this.$route.query.password) {
      this.user.Username = this.$route.query.username
      this.user.Password = this.$route.query.password
    }
  },
  methods: {
    async newPasswordConfirm () {
      const scope = this
      const loggedUser = await Auth.completeNewPassword(this.newPasswordUser, this.newPassword)
        .catch(function (err) {
          scope.$snotify.error(err.message, {
            timeout: 3000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true
          })
          scope.loading = false
        })
      if (loggedUser) {
        scope.$store.commit('session/setIsLoggedIn', true)
        scope.$store.commit('session/setSessionUser', loggedUser.signInUserSession)
        if (scope.$route.query.redirect) {
          scope.$router.push(scope.$route.query.redirect)
        } else {
          if (scope.$store.getters['session/getCognitoGroup']('user')) {
            scope.$router.push('/issues')
          } else {
            scope.$router.push('/developments')
          }
        }
      }
    },
    async resetPassword () {
      const scope = this
      const loggedUser = await Auth.forgotPasswordSubmit(this.user.Username, this.code, this.newPassword)
        .catch(function (err) {
          scope.$snotify.error(err.message, {
            timeout: 3000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true
          })
          scope.loading = false
        })
      if (loggedUser) {
        scope.$store.commit('session/setIsLoggedIn', true)
        scope.$store.commit('session/setSessionUser', loggedUser.signInUserSession)
        if (scope.$route.query.redirect) {
          scope.$router.push(scope.$route.query.redirect)
        } else {
          if (scope.$store.getters['session/getCognitoGroup']('user')) {
            scope.$router.push('/issues')
          } else {
            scope.$router.push('/developments')
          }
        }
      }
    },
    login: function () {
      const scope = this

      this.$validator.validateAll()
        .then(function (res) {
          if (res) {
            scope.loading = true
            Auth.signIn(scope.user.Username, scope.user.Password)
              .then(function (user) {
                if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                  // // You need to get the code from the UI inputs
                  // // and then trigger the following function with a button click
                  // const code = getCodeFromUserInput();
                  // // If MFA is enabled, sign-in should be confirmed with the confirmation code
                  // const loggedUser = await Auth.confirmSignIn(
                  //     user,   // Return object from Auth.signIn()
                  //     code,   // Confirmation code
                  //     mfaType // MFA Type e.g. SMS, TOTP.
                  // );
                } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                  scope.newPasswordRequired = true
                  scope.loading = false
                  scope.newPasswordUser = user
                } else if (user.challengeName === 'MFA_SETUP') {
                  // This happens when the MFA method is TOTP
                  // The user needs to setup the TOTP before using it
                  // More info please check the Enabling MFA part
                  console.log('SETUPTOP')
                } else {
                  // The user directly signs in
                  scope.$store.commit('session/setIsLoggedIn', true)
                  scope.$store.commit('session/setSessionUser', user.signInUserSession)
                  if (scope.$store.getters['session/getCognitoGroup']('user')) {
                    scope.$router.push('/issues')
                  } else {
                    scope.$router.push('/developments')
                  }
                }
              })
              .catch(function (err) {
                console.log(err)
                if (err.code === 'UserNotConfirmedException') {
                  // The error happens if the user didn't finish the confirmation step when signing up
                  // In this case you need to resend the code and confirm the user
                  // About how to resend the code and confirm the user, please check the signUp part
                } else if (err.code === 'PasswordResetRequiredException') {
                  Auth.forgotPassword(scope.user.Username)
                    .then(function () {
                      scope.forgotPassword = true
                      scope.loading = false
                    })
                    .catch(function (err) {
                      scope.$snotify.error(err.message, {
                        timeout: 3000,
                        showProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true
                      })
                      scope.loading = false
                    })
                } else {
                  scope.$snotify.error(err.message, {
                    timeout: 3000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true
                  })
                  scope.loading = false
                }
              })
          }
        })
    }
  }
}
